<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Depoimentos</h4>
          <div class="page-title-right">
            <b-button
              variant="success"
              v-b-tooltip.hover
              title="Adicionar Cupom"
              @click.prevent="adicionar()"
            >
              <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
              Adicionar
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="loading">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="error">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-danger my-2">
              <h5 class="mb-3">Erro ao carregar dados!</h5>
              <img width="250" src="@/assets/images/error-img.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="testimonys.length <= 0">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-5 text-center">
            <h5 class="mb-3">Nenhum item encontrado!</h5>
            <img width="250" src="@/assets/images/not-data.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="row text-left" v-else>
      <div
        v-for="testimony in testimonys"
        :key="testimony.id"
        class="col-xl-4 col-sm-6"
      >
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="avatar-md mr-4" v-if="testimony.image && testimony.image != null && testimony.image != ''">
                <span v-for="img in testimony.image" :key="img.id">
                  <img
                    class="avatar-title rounded bg-light text-danger"
                    :src="`${img.image300}`"
                    alt
                    height="60"
                  />
                </span>
              </div>
               <div class="avatar-md mr-4" v-else>
                  <img
                    class="avatar-title rounded bg-light text-danger"
                    src="@/assets/images/notimg.jpg"
                    alt
                    height="60"
                  />
              </div>

              <div class="media-body overflow-hidden">
                <h5 class="text-truncate font-size-15 text-dark">
                  {{ testimony.name }}
                </h5>
                <p class="text-muted mb-2">
                  {{ testimony.date | moment('DD/MM/YYYY') }}
                </p>
                <p class="text-muted mb-2">
                  {{ testimony.content.substr(0, 50) }}...
                </p>
                <p class="text-muted mb-2">Ordem: {{ testimony.ordem }}</p>
              </div>
            </div>
          </div>
          <div class="px-4 py-3 border-top text-center">
            <button
              class="btn btn-success"
              v-b-tooltip.hover.top
              title="Visualizar"
              @click.prevent="visualizarTestimony(testimony)"
            >
              <i class="fas fa-eye text-white"></i>
            </button>
            <button
              class="btn btn-info ml-2"
              v-b-tooltip.hover.top
              title="Editar"
              @click.prevent="editarTestimony(testimony)"
            >
              <i class="fas fa-edit"></i>
            </button>
            <button
              class="btn btn-danger ml-2"
              v-b-tooltip.hover.top
              title="Remover"
              @click.prevent="removerItem(testimony)"
            >
              <i class="fas fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner" class="text-info"></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
    <b-modal v-model="showModal" title="Depoimento" centered>
      <div class="row">
        <div class="col-12">
          <label class="w-100 cursor-pointer">
            <div class="uploadimg w-100 border p-5 text-center">
              <i class="display-4 text-muted bx bxs-cloud-upload"></i>
              <h6 class="text-muted">clique para enviar imagem</h6>
            </div>
            <input
              type="file"
              @change="onFileChange"
              name="image[]"
              multiple
              class="d-none"
            />
          </label>
        </div>
        <div class="col-12" v-if="img != null">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Imagem</th>
                  <th scope="col" class="text-center">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center align-middle">
                  <td class="text-left align-middle">
                    <b-img width="100" :src="img.url" rounded alt=""></b-img>
                  </td>
                  <td class="align-middle">
                    <b-button variant="danger" @click.prevent="removerImage()">
                      <i class="bx bx-trash font-size-16 align-middle"></i>
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-12 mt-3">
          <label>
            <span class="mr-1 text-danger">*</span>
            Nome
          </label>
          <b-form-group>
            <b-form-input
              placeholder="Nome"
              for="text"
              v-model="form.name"
              :class="{ 'is-invalid': form && $v.form.name.$error }"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12">
          <label>
            Data
          </label>
          <date-picker
            format="DD/MM/YYYY"
            placeholder="Data"
            append-to-body
            lang="pt_BR"
            v-model="form.date"
          ></date-picker>
        </div>
        <div class="col-12 mt-3">
          <label>
            <span class="mr-1 text-danger">*</span>
            Depoimento
          </label>
          <b-form-textarea
            placeholder="Digite aqui..."
            rows="5"
            max-rows="6"
            v-model="form.content"
            :class="{ 'is-invalid': form && $v.form.content.$error }"
          ></b-form-textarea>
        </div>
        <div class="col-12 mt-3">
          <label>
            Ordem
          </label>
          <b-form-group>
            <b-form-input
              placeholder="Ordem"
              type="number"
              min="0"
              v-model="form.ordem"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="danger" @click.prevent="cancelar()">
          Cancelar
        </b-button>
        <b-button variant="success" @click.prevent="salvarData()">
          Salvar
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModalView"
      v-if="testimony != null"
      title="Depoimento"
      centered
    >
      <div class="media">
        <div class="avatar-md mr-4" v-if="testimony.image != null">
          <span v-for="img in testimony.image" :key="img.id">
            <img
              class="avatar-title rounded bg-light text-danger"
              :src="`${img.image300}`"
              alt
              height="60"
            />
          </span>
        </div>
        <div class="avatar-md mr-4" v-else>
            <img
              class="avatar-title rounded bg-light text-danger"
              src="@/assets/images/notimg.jpg"
              alt
              height="60"
            />
        </div>
        <div class="media-body overflow-hidden">
          <h5 class="text-truncate font-size-15">
            <a href="#" class="text-dark">{{ testimony.name }}</a>
          </h5>
          <p class="text-muted mb-2">
            {{ testimony.date | moment('DD/MM/YYYY') }}
          </p>
          <p class="text-muted mb-2">{{ testimony.content }}</p>
          <p class="text-muted mb-2">Ordem: {{ testimony.ordem }}</p>
        </div>
      </div>

      <template v-slot:modal-footer>
        <b-button variant="info" @click.prevent="editarTestimony(testimony)">Editar</b-button>
        <b-button variant="danger" @click.prevent="removerItem(testimony)">Remover</b-button>
        <b-button variant="secondary" @click="cancelar()">
          Fechar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  components: {
    DatePicker,
    InfiniteLoading,
  },
  data() {
    return {
      loading: true,
      error: false,
      showModal: false,
      showModalView: false,
      testimony: null,
      img: null,
      testimonys: [],
      form: {
        content: null,
        name: null,
        date: new Date(),
        ordem: '',
      },
      filtro: {
        limit: 20,
        page: 1,
        order: 'ordem',
      },
    }
  },
  validations: {
    form: {
      name: { required },
      content: { required },
    },
  },
  created() {},
  methods: {
    ...mapActions('testimony', [
      'ActionTestimonyCreate',
      'ActionTestimonyAll',
      'ActionTestimonyUpdate',
      'ActionTestimonyDeleteImage',
    ]),
    salvarData() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitTestomony()
      }
    },
    async submitTestomony() {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Salvando Dados',
          icon: 'info',
          showConfirmButton: false,
        })
        let data = new FormData()

        if (this.img != null && this.img.file != null) {
          data.append('image[]', this.img.file)
        }

        data.append('name', this.form.name)
        data.append('content', this.form.content)
        data.append('ordem', this.form.ordem)
        if (this.form.date != null) {
          data.append(
            'date',
            moment(this.form.date).format('YYYY-MM-DDTHH:mm:ssZ'),
          )
        }

        if (this.form.id != null && this.form.id > 0) {
          data.append('id', this.form.id)
          var dados = {
            data: data,
            id: this.form.id,
          }
          await this.ActionTestimonyUpdate(dados)
        } else {
          await this.ActionTestimonyCreate(data)
        }

        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        }).then((_) => {
          this.showModal = false
          this.form = {
            name: null,
            content: null,
            date: new Date(),
            ordem: '',
          }
          this.listTestimonys()
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
        })
      }
    },
    async onFileChange(e) {
      const file = e.target.files[0]
      if (this.img != null && this.img.file == null && this.img.id != null) {
        await this.ActionTestimonyDeleteImage(this.img.id)
      }
      this.img = {
        file: file,
        url: URL.createObjectURL(file),
        id: null,
      }
    },
    async removerImage() {
      try {
        if (this.img != null && this.img.file == null && this.img.id != null) {
          await this.ActionTestimonyDeleteImage(this.img.id)
        }
        this.img = null
      } catch (_) {
        this.$swal({
          title: 'Erro!',
          text: 'Nào foi possível remover a imagem!',
          icon: 'error',
        })
      }
    },
    cancelar() {
      this.showModalView = false
      this.showModal = false
      this.testimony = null
       this.img = null,
      this.form = {
        name: null,
        content: null,
        date: new Date(),
        ordem: '',
      }
    },
    adicionar() {
      this.form = {
        name: null,
        content: null,
        date: new Date(),
        ordem: '',
      }
      this.img = null,
      this.showModal = true
    },
    async listTestimonys() {
      try {
        this.filtro.page = 1
        this.loading = true
        await this.ActionTestimonyAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page = 2
            this.testimonys = res.data.data.list
          } else {
            this.testimonys = []
          }
          this.loading = false
          this.error = false
        })
      } catch (e) {
        this.loading = false
        this.error = true
        this.testimonys = []
      }
    },

    async infiniteHandler($state) {
      try {
        await this.ActionTestimonyAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page += 1
            this.testimonys.push(...res.data.data.list)
            $state.loaded()
          } else {
            $state.complete()
          }
          this.loading = false
          this.error = false
        })
      } catch (e) {
        this.loading = false
        this.error = true
        this.testimonys = []
        $state.complete()
      }
    },
    visualizarTestimony(data) {
      this.testimony = data
      this.showModalView = true
    },
    editarTestimony(data) {
      this.form = data
      if (this.form.image != null) {
        this.form.image.forEach((element) => {
          this.img = {
            file: null,
            url: element.image300,
            id: element.id,
          }
        })
      }

      this.form.date = new Date(this.form.date)
      this.showModalView = false
      this.showModal = true
    },
    removerItem(data) {
      this.$swal({
        icon: 'warning',
        title: 'Confirmação',
        text: 'Deseja Remover Este Item?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `SIM`,
        denyButtonText: `NÃO`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.removerItemUp(data)
        }
      })
    },
    async removerItemUp(data) {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Removendo item',
          icon: 'info',
          showConfirmButton: false,
        })
        var t = data
        data.trash = 1
        var dados = {
          data: data,
          id: data.id,
        }
        await this.ActionTestimonyUpdate(dados)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
        this.testimonys.splice(this.testimonys.indexOf(t), 1)
        this.showModalView = false
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
  },
}
</script>
